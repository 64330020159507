import { FC } from 'react'
import { cond, matches, constant } from 'lodash'
import type { ClaimStatusDisplay } from './ClaimStatus'
import { CHARGEBACK_THIRD_PARTY_KIND, ChargebackResponse } from '@signifyd/http'
import ExternalLink from 'core/components/ExternalLink'
import {
  CLAIM_DISPOSITION,
  CLAIM_STATUS,
  CLAIM_DISPOSITION_REASON,
} from 'caseReport/Constants'
import type { Claim } from 'core/http/investigations/CaseSearchHttp.types'

type AllClaimStatusDisplay = Record<string, ClaimStatusDisplay>
export type ClaimDataForDisplay = {
  normalizedChargebackReasonType: string | null | undefined
}

const ClaimStatusReimbursementPolicy: FC = () => (
  <span>
    See Signifyd&apos;s{' '}
    <ExternalLink
      saveVisited={false}
      url="https://www.signifyd.com/resources/manual/signifyd-guarantee/reimbursement/"
    >
      reimbursement policy{' '}
    </ExternalLink>
    for more.
  </span>
)

const MailToSignifydClaim: FC = () => (
  <a href="mailto:claims@signifyd.com">claims@signifyd.com</a>
)

type ObjectValue<T> = T[keyof T]

type Target =
  | { status: ObjectValue<typeof CLAIM_STATUS> }
  | {
      status: ObjectValue<typeof CLAIM_STATUS>
      dispositionReason: ObjectValue<typeof CLAIM_DISPOSITION_REASON>
    }
  | {
      disposition: ObjectValue<typeof CLAIM_DISPOSITION>
    }
  | { chargebacks: Array<Partial<ChargebackResponse>> }

type Pattern = [Target, number]

export const getAllClaimStatusDisplay = ({
  normalizedChargebackReasonType,
}: ClaimDataForDisplay): AllClaimStatusDisplay => ({
  _1: {
    header: 'Canceled',
    detail: (
      <span>
        <strong>Chargeback Reversed.</strong> The claim has been canceled since
        the chargeback was reversed by the payment processor.
      </span>
    ),
    color: 'warning',
  },
  _2: {
    header: 'Recalled',
    detail: (
      <span>
        Chargeback recalled by the payment processor and is no longer eligible
        for financial reimbursement by Signifyd.
      </span>
    ),
    color: 'alert',
  },
  _3: {
    header: 'Canceled',
    detail: (
      <span>
        This claim was canceled upon your request. See Notes for more details.
      </span>
    ),
    color: 'warning',
  },
  _4: {
    header: 'Pending',
    detail: (
      <span>
        This claim is currently in review. <ClaimStatusReimbursementPolicy />
      </span>
    ),
    color: 'pending',
  },
  _5: {
    header: 'Needs More Info',
    detail: (
      <span>
        Unable to verify proof of shipment. Please send a valid tracking number
        or other accepted proof of delivery to <MailToSignifydClaim /> so that
        we can process your claim. <ClaimStatusReimbursementPolicy />
      </span>
    ),
    color: 'pending',
  },
  _6: {
    header: 'Needs More Info',
    detail: (
      <span>
        This claim requires additional information to complete our review, see
        Notes for details. Please provide the additional information within 30
        days of request to be eligible for reimbursement.
      </span>
    ),
    color: 'pending',
  },
  _7: {
    header: 'Approved',
    detail: (
      <span>
        Your claim will be processed under the terms of the Agreement.
      </span>
    ),
    color: 'success',
  },
  _8: {
    header: 'Declined',
    detail: (
      <span>
        <strong>Ineligible chargeback. </strong>
        {normalizedChargebackReasonType
          ? `The chargeback reason is ${normalizedChargebackReasonType}. `
          : ''}
        Only chargebacks due to fraud or unauthorized are eligible for
        reimbursement. <ClaimStatusReimbursementPolicy />
      </span>
    ),
    color: 'alert',
  },
  _9: {
    header: 'Declined',
    detail: (
      <span>
        <strong>No financial loss.</strong> This chargeback has been reversed
        and does not require reimbursement.
      </span>
    ),
    color: 'alert',
  },
  _10: {
    header: 'Declined',
    detail: (
      <span>
        <strong>Not a chargeback.</strong> Information submitted shows a
        retrieval request/inquiry by the payment processor, meaning no
        chargeback has been issued. Please respond to them ASAP, as not
        responding can make a future chargeback ineligible for reimbursement.
      </span>
    ),
    color: 'alert',
  },
  _11: {
    header: 'Declined',
    detail: (
      <span>
        <strong>Product rerouted.</strong> This chargeback is ineligible for
        reimbursement as the product was rerouted to a different delivery
        address than provided in the original Guaranteed order details.{' '}
        <ClaimStatusReimbursementPolicy />
      </span>
    ),
    color: 'alert',
  },
  _12: {
    header: 'Declined',
    detail: (
      <span>
        <strong>Product returned.</strong> The products in this order have been
        or are in the process of being returned, as such, this claim is
        ineligible for reimbursement.
      </span>
    ),
    color: 'alert',
  },
  _13: {
    header: 'Declined',
    detail: (
      <span>
        <strong>Order not guaranteed.</strong> This order was not guaranteed by
        Signifyd and is not eligible for reimbursement.{' '}
        <ClaimStatusReimbursementPolicy />
      </span>
    ),
    color: 'alert',
  },
  _14: {
    header: 'Declined',
    detail: (
      <span>
        <strong>Missing information.</strong> 30 days have passed since
        additional information was requested to process this claim, as such,
        chargeback is now ineligible for reimbursement.
      </span>
    ),
    color: 'alert',
  },
  _15: {
    header: 'Declined',
    detail: (
      <span>
        <strong>Past eligible submission date.</strong> Chargebacks must be
        submitted for reimbursement within 7 days of receipt.{' '}
        <ClaimStatusReimbursementPolicy />
      </span>
    ),
    color: 'alert',
  },
  _16: {
    header: 'Declined',
    detail: (
      <span>
        <strong>Claim declined.</strong> Please see Notes for more details.
      </span>
    ),
    color: 'alert',
  },
  _18: {
    header: 'On Hold',
    detail: (
      <span>
        <strong>Pending Representment</strong>: This chargeback is being
        disputed with the payment processor. If the dispute is lost, the claim
        will be released for review.
      </span>
    ),
    color: 'pending',
  },
  _19: {
    header: 'On Hold',
    detail: (
      <span>
        <strong>Invoice Past Due</strong>: Your account is currently past due.
        All claims will be released for processing once the account is current.
      </span>
    ),
    color: 'warning',
  },
  _20: {
    header: 'On Hold',
    detail: (
      <span>
        This claim is on hold for processing. Please email us at{' '}
        <MailToSignifydClaim /> for more information.
      </span>
    ),
    color: 'warning',
  },
  _21: {
    header: 'Declined',
    detail: (
      <span>
        <strong>Not a chargeback.</strong> Information submitted shows this is a
        PayPal Dispute by the payment processor, meaning no chargeback has been
        issued. <ClaimStatusReimbursementPolicy />
      </span>
    ),
    color: 'alert',
  },
  _22: {
    header: 'Declined',
    detail: (
      <span>
        <strong>Not a chargeback.</strong> Information submitted shows this is a
        PayPal Claim by the payment processor, meaning no chargeback has been
        issued. <ClaimStatusReimbursementPolicy />
      </span>
    ),
    color: 'alert',
  },
  _23: {
    header: 'Declined',
    detail: (
      <span>
        <strong>Chargeback reimbursement claim declined.</strong> The
        pre-authorization gateway status indicates this order was unsuccessful
        and is not eligible for reimbursement by Signifyd.{' '}
        <ClaimStatusReimbursementPolicy />
      </span>
    ),
    color: 'alert',
  },
})
export const getClaimStatusDisplayKey = (claim: Claim): string | boolean => {
  const predicate = ([pattern, key]: Pattern) => [
    matches(pattern),
    constant(`_${key}`),
  ]

  const patterns: Array<Pattern> = [
    [
      {
        disposition: CLAIM_DISPOSITION.APPROVED,
        chargebacks: [
          {
            chargebackThirdPartyKind: CHARGEBACK_THIRD_PARTY_KIND.PAYPAL_CLAIM,
          },
        ],
      },
      7,
    ],
    [
      {
        disposition: CLAIM_DISPOSITION.DECLINED,
        chargebacks: [
          {
            chargebackThirdPartyKind: CHARGEBACK_THIRD_PARTY_KIND.PAYPAL_CLAIM,
          },
        ],
      },
      22,
    ],
    [
      {
        chargebacks: [
          {
            chargebackThirdPartyKind:
              CHARGEBACK_THIRD_PARTY_KIND.PAYPAL_DISPUTE,
          },
        ],
      },
      21,
    ],

    [
      {
        status: CLAIM_STATUS.CANCELLED,
        dispositionReason: CLAIM_DISPOSITION_REASON.REVERSAL,
      },
      1,
    ],
    [
      {
        status: CLAIM_STATUS.CANCELLED,
        chargebacks: [
          {
            stage: 'RECALLED',
          },
        ],
      },
      2,
    ],
    [
      {
        status: CLAIM_STATUS.CANCELLED,
      },
      3,
    ],
    [
      {
        status: CLAIM_STATUS.PENDING,
      },
      4,
    ],
    [
      {
        status: CLAIM_STATUS.NEED_MORE_INFO,
        dispositionReason:
          CLAIM_DISPOSITION_REASON.UNABLE_TO_VERIFY_PROOF_OF_SHIPMENT,
      },
      5,
    ],
    [
      {
        status: CLAIM_STATUS.NEED_MORE_INFO,
      },
      6,
    ],
    [
      {
        disposition: CLAIM_DISPOSITION.APPROVED,
      },
      7,
    ],
    [
      {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.INELIGIBLE_CHARGEBACK,
      },
      8,
    ],
    [
      {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason:
          CLAIM_DISPOSITION_REASON.INELIGIBLE_PRE_AUTH_GATEWAY_STATUS_CODE,
      },
      23,
    ],
    [
      {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.NO_FINANCIAL_LOSS,
      },
      9,
    ],
    [
      {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.NOT_A_CHARGEBACK,
      },
      10,
    ],
    [
      {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.PRODUCT_REROUTED,
      },
      11,
    ],
    [
      {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.PRODUCT_RETURNED,
      },
      12,
    ],
    [
      {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.ORDER_NOT_GUARANTEED,
      },
      13,
    ],
    [
      {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason: CLAIM_DISPOSITION_REASON.MISSING_INFORMATION,
      },
      14,
    ],
    [
      {
        disposition: CLAIM_DISPOSITION.DECLINED,
        dispositionReason:
          CLAIM_DISPOSITION_REASON.PAST_ELIGIBLE_SUBMISSION_DATE,
      },
      15,
    ],
    [
      {
        disposition: CLAIM_DISPOSITION.DECLINED,
      },
      16,
    ],
    [
      {
        status: CLAIM_STATUS.ON_HOLD,
        dispositionReason: CLAIM_DISPOSITION_REASON.PENDING_REPRESENTMENT,
      },
      18,
    ],
    [
      {
        status: CLAIM_STATUS.ON_HOLD,
        dispositionReason: CLAIM_DISPOSITION_REASON.INVOICE_PAST_DUE,
      },
      19,
    ],
    [
      {
        status: CLAIM_STATUS.ON_HOLD,
      },
      20,
    ],
  ]

  const getKey = cond(patterns.map(predicate))

  const catchAllKey = '_4'

  return getKey(claim) || catchAllKey
}
